import React from 'react';
import styled from 'styled-components';
import theme from 'style/theme';
import Container from '/components/Container';

interface Props {}

const Wrapper = styled.div`
  position: relative;
`;
const TWayCover = styled.div`
  height: 390px;
  background-image: url('https://d2ifd8cfqxdvq1.cloudfront.net/images/promotion/tway/tway_slider_bg%402x.jpg');
  background-position: bottom center;
  background-size: cover;
  @media ${theme.sm} {
    height: 500px;
    background-position: center;
    background-image: url('https://d2ifd8cfqxdvq1.cloudfront.net/images/promotion/tway/tway_slider_bg.jpg');
  }
`;
const TWayTextBox = styled.div`
  position: absolute;
  left: 30px;
  top: 50px;
  @media ${theme.sm} {
    top: 165px;
    left: 20px;
  }
`;
const TWayTag = styled.div`
  margin-bottom: 35px;
  font-size: 18px;
  font-weight: ${theme.bold};
  line-height: 21px;
  color: #92db22;
  padding: 0;
  margin-right: 0;
  @media ${theme.sm} {
    margin-bottom: 50px;
  }
`;
const TWayTitle = styled.div`
  font-size: 35px;
  line-height: 41px;
  color: #fff;
  font-weight: ${theme.bold};
  letter-spacing: -0.2px;
  strong {
    color: #92db22;
  }
  @media ${theme.sm} {
    font-size: 45px;
    line-height: 53px;
  }
`;
const TWayDescription = styled.div`
  margin-top: 10px;
  color: #fff;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.2px;
  .block-on-xs {
    display: block;
  }
  @media ${theme.sm} {
    font-size: 20px;
    color: #92db22;
    .white-on-sm {
      color: #fff;
    }
    .block-on-xs {
      display: inline;
    }
  }
  strong {
    color: #fff;
  }
`;
const TWayCouponCode = styled.div`
  display: block;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  background-color: #0097a7;
  padding: 0 10px;
  color: #fff;
  @media ${theme.sm} {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    background-color: transparent;
    font-size: 20px;
  }
`;
const BlockBannerTWay = (props: Props) => {
  return (
    <Wrapper>
      <TWayCover>
        <Container width={964}>
          <TWayTextBox>
            <TWayTag>Trazy X t’way</TWayTag>
            <TWayTitle>
              <strong>GET EXTRA 4%</strong> DISCOUNT
            </TWayTitle>
            <TWayDescription>
              <span>on all tours & activities on Trazy </span>
              <span className="show-sm-inline-block white-on-sm"> | </span>
              <span className="white-on-sm block-on-xs">
                {' '}
                Exclusively for <strong>t’way Customers</strong>
              </span>
            </TWayDescription>
          </TWayTextBox>
        </Container>
      </TWayCover>
      <TWayCouponCode>
        <Container width={964}>
          Use discount code: <strong>twayft4</strong>
        </Container>
      </TWayCouponCode>
    </Wrapper>
  );
};

export default BlockBannerTWay;
