// @ts-nocheck
import React, { useRef } from 'react';
import styled from 'styled-components';
import theme from '/style/theme';
import Link from 'next/link';
import { transition } from '/style/mixin';
import useLazyBg from '/utils/useLazyBg';

const Wrapper = styled.div<{
  thumbnail_image: string;
  thumbnail_image_mobile: string;
}>`
  position: relative;
  padding: 0 5px;
  a {
    position: relative;
    display: block;
    width: 162px;
    height: 231px;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    @media ${theme.lg} {
      width: 180px;
      height: 258px;
    }
    opacity: 0;
    ${transition('.2s opacity ease-out')}
    &.on-screen {
      opacity: 1;
      background-image: url("${props => props.thumbnail_image_mobile}");
      @media ${theme.md} {
        background-image: url("${props => props.thumbnail_image}");
      }
    }
    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.4) none repeat scroll 0 0;
      opacity: 1;
      ${transition()}
    }
    &:hover:before {
      opacity: 0;
    }
  }
  .text-wrap {
    text-align: center;
    position: absolute;
    z-index: 2;
    top: 40px;
    right: 0;
    left: 0;
  }
  .title {
    margin: 0;
    font-size: 21px;
    color: #fff;
    font-weight: ${props => props.theme.bold};
    line-height: 1.2;
    text-transform: uppercase;
  }
  .subtitle {
    margin-top: 8px;
    font-size: 15px;
    line-height: 1.53;
    color: #fff;
    @media ${theme.lg} {
      margin-top: 5px;
    }
  }
`;
interface DataProps {
  thumbnail_image: string;
  thumbnail_image_mobile: string;
  city_name: string;
  country_name: string;
  custom_url: string;
}
interface Props {
  data: DataProps;
}

const CityItem = ({ data }: Props) => {
  const target = useRef(null);
  useLazyBg(target);
  return data.custom_url ? (
    <Wrapper thumbnail_image={data.thumbnail_image} thumbnail_image_mobile={data.thumbnail_image_mobile}>
      <Link
        href={`/things-to-do/[slug]`}
        as={`/things-to-do/${data.featured_url}`}
      >
        <a ref={target} data-src={data.thumbnail_image} title={`Top Things To Do in ${data.city_name}`}>
          <div className="text-wrap">
            <div className="title">{data.city_name}</div>
            <div className="subtitle">{data.country_name}</div>
          </div>
        </a>
      </Link>
    </Wrapper>
  ) : null;
};

export default CityItem;
