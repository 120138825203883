import { useMemo } from 'react';
import styled from 'styled-components';
import theme from '/style/theme';
import Link from 'next/link';
import getLinkInfo from '/utils/getLinkInfo';

const Campaign = styled.div`
  height: 0;
  padding-bottom: 16%;
  background-image: url(${props => props.mobile_banner});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-indent: -9999px;
  @media ${theme.sm} {
    background-image: url(${props => props.web_banner});
    padding-bottom: 12.0643%;
    ${props =>
      props.wide &&
      `
      padding-bottom: 9.376%;
      ${props.web_banner_wide && `
        background-image: url(${props.web_banner_wide});
      `}
    `}
  }
`;

export default ({ data, wide }) => {
  const output = (
    <Campaign
      wide={wide}
      mobile_banner={data.mobile_banner}
      web_banner={data.web_banner}
      web_banner_wide={data.web_banner_wide}
    >
      {data.campaign_title}
    </Campaign>
  );
  let banner_link;
  let innerlink = true;
  if (data && data.banner_link) {
    const link_info = useMemo(() => getLinkInfo(data.banner_link), [data.banner_link]);
    banner_link = link_info.link;
    innerlink = link_info.is_inner_link;
  }
  return data && data.banner_link ? (
    <Link href={banner_link} prefetch={false}>
      <a target={innerlink?'_self':'_blank'}>{output}</a>
    </Link>
  ) : (
    output
  );
};
