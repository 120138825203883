// @ts-nocheck
import styled from 'styled-components';

const Wrapper = styled.div`
  display: inline-block;
  width: 33.3333%;
  vertical-align: top;
  .icon {
    img {
      display: block;
      margin: 0 auto 20px;
      width: 58px;
      height: 58px;
    }
  }
  .title {
    margin: 20px 0 0;
    font-size: 21px;
    color: ${props => props.theme.mid_gy};
    font-weight: ${props => props.theme.bold};
    line-height: 1.2;
  }
  .description {
    margin-top: 15px;
    font-size: 15px;
    line-height: 1.53;
    color: ${props => props.theme.mid_gy};
    strong {
      font-size: 18px;
      font-weight: ${props => props.theme.bold};
    }
  }
`;

const FeatureItem = ({ title, description, children }) => {
  return (
    <Wrapper>
      <div className="icon">{children}</div>
      <h4 className="title">{title}</h4>
      <div className="description">
        {description.map((desc, i) => (
          <span key={`desc${i}`}>{desc}</span>
        ))}
      </div>
    </Wrapper>
  );
};

export default FeatureItem;
