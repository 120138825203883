import styled from 'styled-components';
import theme from '/style/theme';

const Wrapper = styled.div`
  position: relative;
  padding-top: 39px;
  margin-bottom: 30px;
  text-align: center;
  @media ${theme.sm} {
    margin-bottom: 34px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 46px;
    height: 9px;
    margin-left: -23px;
    background-image: url('${require('/public/images/ui/ico-crazy.svg')}');
    background-repeat: no-repeat;
    background-position: center center;
    ${props =>
      props.mint &&
      `
    background-image: url('${require('/public/images/ui/ico-crazy_mint.svg')}');
    `}
  }
  .title {
    font-size: 20px;
    margin: 0;
    line-height: 1.2;
    letter-spacing: -0.2px;
    text-align: center;
    font-weight: ${props => props.theme.bold};
    color: ${props => props.theme.dark_gy};
    @media ${theme.sm} {
      font-size: 25px;
    }
  }
  .subtitle {
    margin-top: 5px;
    font-size: 15px;
    line-height: 1.67;
    letter-spacing: -0.2px;
    color: ${props => props.theme.gy};
    @media ${theme.sm} {
      font-size: 17px;
      margin-top: 10px;
    }
  }
`;

const BlockHeading = ({ title, subtitle, mint }) => {
  return (
    <Wrapper mint={mint}>
      <h3 className="title">{title}</h3>
      {subtitle ? <div className="subtitle">{subtitle}</div> : null}
    </Wrapper>
  );
};

export default BlockHeading;
