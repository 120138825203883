import React from 'react';
import styled from 'styled-components';
import theme from '/style/theme';
import ListItemBox from '/components/list/ListItemBox';
import TypeActivityThemeItem from '/type/TypeActivityThemeItem';
import Link from 'next/link';
import parse from '/utils/parse';
import useTranslation from 'next-translate/useTranslation';

interface Props {
  data: TypeActivityThemeItem;
  fixed_width?: string;
}

interface WrapperProps {
  cover_image?: string;
}

const Wrapper = styled.div<WrapperProps>`
  .item-bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    ${props =>
      props.cover_image &&
      `
        background-image: url("${props.cover_image}");
      `}
  }
  .shade {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 236px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
  }
  .item-text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px;
    color: #fff;
  }
  .subtitle {
    font-size: 13px;
    font-weight: ${theme.bold};
    line-height: 1.92;
    letter-spacing: -0.19px;
    color: #fff;
  }
  .title {
    font-size: 16px;
    font-weight: ${theme.bold};
    line-height: 1.56;
    letter-spacing: -0.2px;
  }
  .item-meta {
    margin-top: 10px;
  }
  .meta {
    display: inline-block;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: -0.2px;
    vertical-align: top;
    margin-right: 10px;
    text-transform: uppercase;
    &:before {
      content: '';
      display: inline-block;
      vertical-align: top;
      background-position: center center;
      background-repeat: no-repeat;
      margin-top: 2px;
      margin-right: 5px;
    }
    &_location {
      &:before {
        width: 8px;
        height: 10px;
        background-image: url(${require('/public/images/ui/ico-location_solid.svg')});
      }
    }
  }
`;

const ActivityThemeItem: React.FC<Props> = ({ data, fixed_width }) => {
  const { t } = useTranslation('common');
  return (
    <Wrapper className="item" cover_image={data.cover_image}>
      <ListItemBox fixed_width={fixed_width}>
        <Link
          href={`/activity-theme/[slug]`}
          as={`/activity-theme/${data.custom_url}`}
        >
          <a className="item-bg" target="_blank" rel="noreferrer noopener" title={data.theme_title}>
            <div className="shade" />
            <div className="item-text">
              <div className="subtitle">{t('n-things-to-do', {count: data.total_exp})}</div>
              <div className="title">{parse(data.theme_title)}</div>
              <div className="item-meta">
                <span className="meta meta_location">
                  <span className="sr-only">location </span>
                  {data.country}
                </span>
              </div>
            </div>
          </a>
        </Link>
      </ListItemBox>
    </Wrapper>
  );
};

export default ActivityThemeItem;
