// @ts-nocheck
import React, { useRef } from 'react';
import styled from 'styled-components';
import theme from '/style/theme';
import ListItemBox from '/components/list/ListItemBox';
import TypeCampaignItem from '/type/TypeCampaignItem';
import Link from 'next/link';
import { connect } from 'react-redux';
import useLazyBg from '/utils/useLazyBg';
import { transition } from '/style/mixin';

interface Props {
  data: TypeCampaignItem;
  fixed_width?: string;
}
interface WrapperProps {
  thumbnail_h?: string;
  thumbnail_v?: string;
}

const Wrapper = styled.div<WrapperProps>`
  .item-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    text-indent: -9999px;
    opacity: 0;
    ${transition('.2s opacity ease-out')}
    &.on-screen {
      opacity: 1;
      background-image: url("${props => props.thumbnail_h}");
      @media ${theme.lg} {
        background-image: url("${props => props.thumbnail_v}");
      }
    }
  }
`;

const CampaignItem: React.FC<Props> = ({
  data,
  fixed_width,
  uistatus,
}: Props) => {
  const target = useRef(null);
  useLazyBg(target);
  let url_type = 'campaign';
  if (data.banner_link.indexOf('/event') === 0) url_type = 'event';
  return (
    <Wrapper thumbnail_h={data.thumbnail_h} thumbnail_v={data.thumbnail_v}>
      <ListItemBox fixed_width={fixed_width}>
        <Link href={data.banner_link} prefetch={false}>
          <a
            className="item-bg"
            ref={target}
            target={uistatus.screenwidth === 'xs' ? '_self' : '_blank'}
            data-src={
              uistatus.screenwidth === 'lg'
                ? data.thumbnail_v
                : data.thumbnail_h
            }
            rel="noopener noreferrer"
            title={data.campaign_title}
          >
            {data.campaign_title}
          </a>
        </Link>
      </ListItemBox>
    </Wrapper>
  );
};

export default connect(state => ({
  uistatus: state.uistatus,
}))(CampaignItem);
