// @ts-nocheck
import React, { useEffect } from 'react';
import { NextSeo } from 'next-seo';
import Layout from '/components/Layout';
import BlockCategories from '/components/Home/BlockCategories';
import BlockCarousel from '/components/Home/BlockCarousel';
import BlockFeatures from '/components/Home/BlockFeatures';
import BlockDestinations from '/components/Home/BlockDestinations';
import BlockActivities from '/components/Home/BlockActivities';
import BlockCoverTWay from '/components/Home/BlockCoverTWay';
import BlockFixedBanner from '/components/Home/BlockFixedBanner';
import styled from 'styled-components';
import { connect } from 'react-redux';
import actions from '/store/actions';
import { getCookie } from '/utils/cookie';
import TypeCouponCampaign from '/type/TypeCouponCampaign';
import TypeCampaignBanner from '/type/TypeCampaignBanner';
import Container from '/components/Container';
import CampaignBanner from '/components/ui/CampaignBanner';
import LayoutBadConnection from '/components/LayoutBadConnection';
import BlockFeaturedBlog from './BlockFeaturedBlog';
import useTranslation from "next-translate/useTranslation";

interface QueryObject {
  redirect: string;
}
interface Props {
  data: DataObject;
  query: QueryObject;
  changeModalMember: (value: object) => void;
}
interface DataObject {
  meta_title: string;
  meta_description: string;
  og_title: string;
  og_description: string;
  og_image: string;
  keywords: string;
  slider_list: Array<object>;
  reserve_num: number;
  category_list: Array<object>;
  city_list: Array<object>;
  city_list_title: string;
  city_list_subtitle: string;
  city_list_more: string;
  activity_list1: Array<object>;
  activity_list_title1: string;
  activity_list_subtitle1: string;
  activity_list_more1: string;
  activity_list2: Array<object>;
  activity_list_title2: string;
  activity_list_subtitle2: string;
  activity_list_more2: string;
  activity_list3: Array<object>;
  activity_list_title3: string;
  activity_list_subtitle3: string;
  activity_list_more3: string;
  blog_list: Array<object>;
  blog_list_title: string;
  blog_list_subtitle: string;
  blog_list_more: string;
  coupon_campaign: TypeCouponCampaign;
  campaign: TypeCampaignBanner;
}

const Wrapper = styled.div``;

const BlockBanner = styled.div<{
  banner_bg: string;
}>`
  background-color: ${props => props.banner_bg};
`;

const LayoutHome = ({ data, query, home_type, changeModalMember }: Props) => {
  useEffect(() => {
    if (query && query.redirect) {
      changeModalMember({
        type: 'login',
        show: true,
      });
    }
    if (query && query.reset_password) {
      changeModalMember({
        type: 'password',
        show: true,
      });
    }
  }, []);

  const { lang } = useTranslation();
  const { translated } = data;
  return data ? (
    <Layout padding_top="0" mobile_header_transparent={!home_type} show_organization affiliate_partner={home_type}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            {
              '@context': 'http://schema.org',
              '@type': 'WebSite',
              name: 'Trazy',
              alternateName: 'Trazy.com',
              url: 'https://www.trazy.com',
              potentialAction: {
                '@type': 'SearchAction',
                target: 'https://www.trazy.com/search?keyword={search_term_string}',
                'query-input': 'required name=search_term_string',
              },
            },
            null,
            2,
          ),
        }}
      />
      <NextSeo
        title={data.meta_title}
        description={data.meta_description}
        openGraph={{
          title: data.og_title,
          description: data.og_description,
          url: 'https://www.trazy.com/' + (lang != 'en' ? lang : ''),
          images: [
            {
              url: data.og_image,
            },
          ],
        }}
        canonical={'https://www.trazy.com/' + (lang != 'en' && translated? lang : '')}
        additionalMetaTags={data.keywords ? [
          {
            name: 'keywords',
            content: data.keywords,
          },
        ] : null}
      />
      <h2 className="sr-only">Main page</h2>
      <Wrapper>
        {home_type === 'tway' ? (
          <BlockCoverTWay />
        ) : data.slider_list ? (
          <BlockCarousel data={data.slider_list} />
        ) : null}
        {data.campaign ? (
          <BlockBanner banner_bg={data.campaign.banner_bg}>
            <Container xs_padding="0" sm_padding="0" md_padding="0">
              <CampaignBanner data={data.campaign} wide>
                {data.campaign.campaign_title}
              </CampaignBanner>
            </Container>
          </BlockBanner>
        ) : null}
        <BlockFeatures data={data.reserve_num || 0} />
        <BlockCategories data={data.category_list} />
        <BlockDestinations
          data={data.city_list}
          title={data.city_list_title}
          subtitle={data.city_list_subtitle}
          more={data.city_list_more}
        />
        <BlockActivities
          data={data.activity_list1}
          title={data.activity_list_title1}
          subtitle={data.activity_list_subtitle1}
          more={data.activity_list_more1}
          no_border
        />
        <BlockActivities
          data={data.activity_list2}
          title={data.activity_list_title2}
          subtitle={data.activity_list_subtitle2}
          more={data.activity_list_more2}
        />
        <BlockActivities
          data={data.activity_list3}
          title={data.activity_list_title3}
          subtitle={data.activity_list_subtitle3}
          more={data.activity_list_more3}
        />
        {data.blog_list && data.blog_list.length ? (
          <BlockFeaturedBlog
            data={data.blog_list}
            title={data.blog_list_title}
            subtitle={data.blog_list_subtitle}
            more={data.blog_list_more}
          />
        ) : null}
        {!getCookie('tr_homebanner_closed') && data.coupon_campaign ? (
          <BlockFixedBanner data={data.coupon_campaign} />
        ) : null}
      </Wrapper>
    </Layout>
  ) : (
    <LayoutBadConnection />
  );
};

export default connect(state => state, actions)(LayoutHome);
