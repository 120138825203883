import styled from 'styled-components';
import Carousel from '/components/ui/Carousel';
import ElementCarouselItem from './ElementCarouselItem';
import theme from '/style/theme';

const Wrapper = styled.section`
  position: relative;
  .slick-dots {
    position: absolute;
    bottom: 22px;
    left: 0;
    right: 0;
    li {
      button {
        background-color: #fff;
        opacity: 0.8;
      }
      &.slick-active button {
        background-color: #fff;
        opacity: 1;
      }
    }
  }
  .slick-play-pause {
    position: absolute;
    z-index: 1;
    bottom: 20px;
    left: 50%;
  }
  .slick-dots .slick-active button {
    width: 20px;
  }
  .slick-arrow {
    @media ${theme.md_max} {
      display: none !important;
    }
  }
`;

const BlockCarousel = ({ data }) => {
  const settings = {
    dots: true,
    arrows: true,
    autoplay: data ? data.length > 1 : false,
    fade: true,
    speed: 1000,
  };
  return data ? (
    <Wrapper>
      <h3 className="sr-only">Home Carousel</h3>
      <Carousel {...settings}>
        {data.map(v => (
          <ElementCarouselItem data={v} key={v.main_title} />
        ))}
      </Carousel>
    </Wrapper>
  ) : null;
};

export default BlockCarousel;
