import React, { useMemo } from 'react';
import styled from 'styled-components';
import theme from '/style/theme';
import Link from 'next/link';
import getLinkInfo from '/utils/getLinkInfo';
import useTranslation from 'next-translate/useTranslation';

const Wrapper = styled.div`
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('${props => props.bg_image_mobile}');
  height: 450px;
  a {
    display: block;
    height: 450px;
  }
  @media ${theme.sm} {
    height: 500px;
    background-image: url('${props => props.bg_image_desktop}');
    a {
      height: 500px;
    }
  }
  .text-wrap {
    position: absolute;
    top: 169px;
    left: 30px;
    right: 30px;
    @media ${theme.sm} {
      top: 241px;
      left: 50px;
      right: 50px;
    }
    @media ${theme.lg} {
      .text-container {
        max-width: 768px;
        padding: 0 50px;
        margin: 0 auto;
      }
    }
    .tags {
      margin-bottom: 15px;
    }
    .tag {
      border-radius: 5px;
      background-image: linear-gradient(to right, ${theme.light_og}, #f06544);
      font-size: 14px;
      line-height: 26px;
      font-weight: 600;
      padding: 0 10px;
      color: #fff;
      margin-right: 5px;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 5px;
    }
    .title {
      margin: 0;
      font-size: 35px;
      font-weight: ${props => props.theme.bold};
      line-height: 1.2;
      letter-spacing: -0.18px;
      color: ${props => props.main_title_color};
      @media ${theme.sm} {
        font-size: 40px;
      }
    }
    .subtitle {
      margin: 10px 0 0;
      font-size: 18px;
      font-weight: ${props => props.theme.normal};
      line-height: 1.2;
      letter-spacing: -0.2px;
      color: ${props => props.sub_title_color};
    }
    .link {
      display: block;
      margin-top: 21px;
      font-size: 14px;
      line-height: 16px;
      margin-top: 32px;
      color: #464646;
      letter-spacing: -0.2px;
      text-decoration: underline;
    }
  }
  ${props =>
    props.type === 1 &&
    `
      .text-wrap {
        top: 130px;
        @media ${theme.sm} {
          top: 191px;
        }
        .title {
          font-size: 25px;
          letter-spacing: -0.17px;
          @media ${theme.sm} {
            font-size: 30px;
            line-height: 40px;
            letter-spacing: -0.2px;
          }
        }
        .subtitle {
          font-size: 15px;
          letter-spacing: -0.2px;
        }
      }
      
    `}
`;
const CarouselItemInner = ({
  discount_tags,
  main_title,
  sub_title,
  custom_url,
}) => {
  const { t } = useTranslation('home');
  return (
    <div className="text-wrap">
      <div className="text-container">
        {discount_tags && discount_tags.length ? (
          <div className="tags">
            {discount_tags.map(v => (
              <span className="tag" key={v}>
                {v}
              </span>
            ))}
          </div>
        ) : null}
        <h4 className="title">{main_title}</h4>
        <div className="subtitle">{sub_title}</div>
        {custom_url ? <span className="link">{t('show-more')} &gt;</span> : null}
      </div>
    </div>
  );
};
const ElementCarouselItem = ({ data }) => {
  let link = '/';
  let is_inner_link = true;
  if (data && data.custom_url) {
    const link_info = useMemo(() => getLinkInfo(data.custom_url), [data.custom_url]);
    link = link_info.link;
    is_inner_link = link_info.is_inner_link;
  }
  return (
    <Wrapper
      type={data.type}
      sub_title_color={data.sub_title_color}
      main_title_color={data.main_title_color}
      bg_image_desktop={data.bg_image_desktop}
      bg_image_mobile={data.bg_image_mobile}
    >
      {link ? (
        <Link href={link} prefetch={false}>
          <a
            target={is_inner_link ? '_self' : '_blank'}
            rel={is_inner_link ? '' : 'noopener noreferrer'}
          >
            <CarouselItemInner
              discount_tags={data.discount_tags}
              main_title={data.main_title}
              sub_title={data.sub_title}
              custom_url={data.custom_url}
            />
          </a>
        </Link>
      ) : (
        <CarouselItemInner
          discount_tags={data.discount_tags}
          main_title={data.main_title}
          sub_title={data.sub_title}
        />
      )}
    </Wrapper>
  );
};

export default ElementCarouselItem;
